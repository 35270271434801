<template>
  <div class="null-page">
      <img class="img" src="../../assets/null.png" alt="" />
      <p>{{text}}</p>
  </div>
</template>

<script>
  export default {
    components:{
    },
    data() {
      return {
          text: '该96111标识牌未绑定电梯，请联系电梯使用单位或维保单位处理',
      };
    },
    methods: {
    }
  };
</script>

<style scoped >

  .null-page{
    background-color: #F2F2F2;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .img{
      width: 200px;
      height: 200px;
  }
  p{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 16px;
    color: #999;
  }


</style>