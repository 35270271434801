<template>
  <div class="app">
    <div class="min" style="min-height: 850px">
      <div class="banner">
        <span class="banner-one">{{ elevator.communityName }}</span>
        <span class="banner-one">{{ " " }}</span>
        <span class="banner-too">{{ elevator.systemCode }}</span>
        <p class="banner-too">{{ elevator.emergencyCode }}</p>
      </div>

      <div class="info" v-if="showData">
        <h5>电梯安全运行数据</h5>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info1.png" alt="" />
            <span>电梯运行状态</span>
            <p :style="{ color: statusColor }">
              {{ statusText }}
            </p>
          </div>
          <div class="info-item">
            <img src="../../assets/info2.png" alt="" />
            <span>数据接入、分配状态</span>
            <p :style="{ fontSize: '20px', color: iotStatusColor }">
              {{ iotStatusText }}
            </p>
          </div>
        </div>

        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/maintenance.svg" alt="" />
            <span>最近数据时间</span>
            <p v-if="shouldShowLastTime">
              {{ elevatorIot.iotLastTime | formatDateTime() }}
            </p>
          </div>
        </div>
      </div>

      <div class="info">
        <h5>基础信息</h5>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info1.png" alt="" />
            <span>出厂编号</span>
            <p>{{ elevator.produceCode }}</p>
          </div>
          <div class="info-item">
            <img src="../../assets/info2.png" alt="" />
            <span>设备代码</span>
            <p>{{ elevator.code }}</p>
          </div>
        </div>

        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info3.png" alt="" />
            <span>电梯品牌</span>
            <p>{{ elevator.brandName }}</p>
          </div>
        </div>

        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info6.png" alt="" />
            <span>监检日期</span>
            <p>{{ elevator.approveddate | formatDate() }}</p>
          </div>
          <div class="info-item">
            <img src="../../assets/info6.png" alt="" />
            <span>下次年检日期</span>
            <p>{{ elevator.annualCheckTime | formatDate() }}</p>
          </div>
        </div>

        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/maintenance.svg" alt="" />
            <span>预计下次维保日期</span>
            <p>{{ elevator.nextMaintenanceTime | formatDate() }}</p>
          </div>
          <div class="info-item">
            <img src="../../assets/maintenance.svg" alt="" />
            <span>上次维保日期</span>
            <p>{{ elevator.lastMaintenanceTime | formatDate() }}</p>
          </div>
        </div>
      </div>

      <div class="main">
        <div v-if="elevator.maintenanceName" class="main-item">
          <img src="../../assets/user.png" alt="" />
          <span class="item-top">{{ elevator.maintenanceName }}</span>
          <div class="main-item-outside">
            <span class="phone">24小时值班电话</span>
            <div class="whole">
              <a :href="`tel:${elevator.workTelphone}`">
                <span class="phone-number">{{ elevator.workTelphone }}</span>
                <span class="vertical">|</span>
                <van-icon name="phone-o" color="#3369FD" size="16" />
              </a>
            </div>
          </div>
          <div class="phone" v-show="isShow">
            <span>维保单位资质</span>
            <div
              v-for="item in certificationList"
              :key="item.id"
              class="certification-content"
            >
              <span
                >{{
                  (objDict[item.elevatorType]
                    ? objDict[item.elevatorType]
                    : "") +
                  " | " +
                  (objDict[item.certificationType]
                    ? objDict[item.certificationType]
                    : "") +
                  " | " +
                  item.certificationLevel +
                  "级"
                }}
              </span>
            </div>
          </div>
          <div class="info-closed" @click="closed">
            <span>{{ text }}</span>
            <img class="img-closed" :src="imageScreen" alt="" />
          </div>
        </div>

        <div v-if="elevator.companyName" class="main-item">
          <img src="../../assets/user.png" alt="" />
          <span class="item-top">{{ elevator.companyName }}</span>
          <div class="main-item-outside">
            <span class="phone">24小时值班电话</span>
            <div class="whole">
              <a :href="`tel:${elevator.companyWorkTelphone}`">
                <span class="phone-number">{{
                  elevator.companyWorkTelphone
                }}</span>
                <span class="vertical">|</span>
                <van-icon name="phone-o" color="#3369FD" size="16" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="title">近30天维保记录</div>
      <div
        class="info"
        v-for="item in list"
        :key="item.id"
        style="margin-top: 5px; margin-bottom: 10px; padding-top: 2px"
        @click="goInfo(item.id)"
      >
        <div class="info-outside">
          <div class="info-item">
            <p>{{ getMaintenanceType(item.maintenanceItemName) }}</p>
            <p>维保完成提交时间</p>
            <p>{{ item.finishTime | formatDate() }}</p>
          </div>
          <!-- <div class="info-item" style="margin-left: 10px;">
            <div style="height: 25px;">&nbsp;</div>
            <p>使用单位确认时间</p>
            <p>{{item.audittime| formatDate()}}</p>
          </div> -->
        </div>
      </div>
      <div
        v-if="list.length == 0"
        class="info"
        style="
          text-align: center;
          font-size: 16px;
          margin-bottom: 10px;
          padding: 30px;
        "
      >
        暂无维保记录
      </div>
    </div>

    <div>
      <a>
        <van-button
          type="info"
          size="small"
          :round="true"
          style="margin-left: 60px"
          @click="reply()"
        >
          <van-icon name="description" color="#fff" />
          乘梯安全知识</van-button
        >
      </a>
      <a :href="`tel:96111`">
        <van-button
          type="info"
          size="small"
          :round="true"
          style="margin-left: 40px"
        >
          <van-icon name="phone-o" color="#fff" />
          96111应急电话
        </van-button>
      </a>
    </div>
  </div>
</template>

<script>
import {
  getElevatorDetail,
  getCompanyCertificationList,
  getDictList,
  getElevatorIot,
} from "../../api/public";
import preventBack from "vue-prevent-browser-back"; // 组件内单独引入
export default {
  mixins: [preventBack], // 注入
  data() {
    return {
      value1: null,
      value2: "a",
      value: 5,
      elevator: {
        communityName: "",
        systemCode: "",
        emergencyCode: "",
        code: "",
        brandName: "",
        deadWeight: "",
        limitNumber: "",
        lastMaintenanceTime: "",
        nextMaintenanceTime: "",
        approveddate: "",
        annualCheckTime: "",
        maintenanceName: "",
        workTelphone: "",
        companyName: "",
        companyWorkTelphone: "",
      },
      elevatorIot: {
        status: "",
        iotStatus: "",
        iotLastTime: "",
        monitorCode: "",
      },
      list: [],
      certificationList: [],
      objDict: {},
      isShow: false,
      text: "展开",
      imageScreen: require("@/assets/arrow-x.png"),
      statusColor: null,
      statusText: "",
      iotStatusColor: null,
      iotStatusText: "",
      shouldShowLastTime: false,
      showData: false,
    };
  },
  created() {
    this.getDatail();
    this.getDictList();
  },
  methods: {
    goInfo(id) {
      this.$router.push({
        path: "maintenance",
        query: {
          id: id,
        },
      });
    },
    reply() {
      this.$router.push("reply");
    },
    closed() {
      this.isShow = !this.isShow;
      if (this.isShow === true) {
        this.text = "收起";
        this.imageScreen = require("@/assets/arrow-s.png");
      } else {
        this.text = "展开";
        this.imageScreen = require("@/assets/arrow-x.png");
      }
    },
    // 维保类型字典值
    getMaintenanceType(value) {
      if (value === "HALF_MONTH") {
        return "半月保";
      } else if (value === "QUARTER") {
        return "季度保";
      } else if (value === "HALF_YEAR") {
        return "半年保";
      } else if (value === "YEAR") {
        return "年度保";
      }
    },
    getDatail() {
      try {
        const query = {
          elevatorId: this.$route.query.elevatorId,
        };
        getElevatorDetail(query).then((res) => {
          if (
            res.data.elevatorResult == null ||
            res.data.maintenanceOrderList == null
          ) {
            this.$toast.fail({
              message: "当前应急编码尚未绑定电梯，请维护保养单位进行认领绑定",
              duration: 4000,
            });
            return;
          }

          if (res.data.elevatorResult != null) {
            this.elevator = res.data.elevatorResult;
            const approvedDateThreshold = new Date("2019-06-01");
            const approvedDate = new Date(this.elevator.approveddate);
            if (approvedDate > approvedDateThreshold) {
              this.showData = true;
              getElevatorIot({
                monitorCode: res.data.elevatorResult.monitorCode,
              }).then((res) => {
                this.elevatorIot = res.data;
                this.getStatus(
                  res.data !== null ? res.data.iotStatus : null,
                  res.data !== null ? res.data.status : null
                );
              });
            }
          }
          if (
            res.data.maintenanceOrderList != null &&
            res.data.maintenanceOrderList.length > 0
          ) {
            this.list = res.data.maintenanceOrderList;
            this.getCompanyCertificationList(
              res.data.elevatorResult.maintenanceId
            );
          }
        });
      } catch (error) {}
    },
    getStatus(iotStatus, status) {
      if (iotStatus == null) {
        this.iotStatusColor = "red";
        this.iotStatusText = "待完成";
      } else {
        const isNormal = iotStatus === "2" || iotStatus === "3";
        this.iotStatusColor = isNormal ? "green" : "red";
        this.iotStatusText = isNormal ? "正常" : "待完成";

        if (isNormal) {
          const statusMap = {
            1: { color: "#67c23a", text: "正常" },
            2: { color: "#e6a23c", text: "检修" },
            3: { color: "#f56c6c", text: "困人" },
            4: { color: "#f56c6c", text: "故障" },
            5: { color: "#e6a23c", text: "异常" },
            6: { color: "#e6a23c", text: "报警" },
          };

          const statusInfo = statusMap[status] || {
            color: "#000000",
            text: "未知状态",
          };
          this.statusColor = statusInfo.color;
          this.statusText = statusInfo.text;
          this.shouldShowLastTime = true;
        }
      }
    },
    async getCompanyCertificationList() {
      try {
        const id = this.elevator.maintenanceId;
        const response = await getCompanyCertificationList({
          id: id,
        });

        this.certificationList = response.data;
      } catch (error) {}
    },
    async getDictList() {
      try {
        const response = await getDictList();
        if (response.data) {
          for (let i = 0; response.data[i]; i++) {
            const item = response.data[i];
            this.objDict[item.code] = item.name;
          }
        }
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
/* .min {
background-color: #F9F9F9;
padding: 0 15px;
} */
.banner {
  width: 100%;
  /* height: 204px; */
  background: url(../../assets/bj.png) no-repeat;
  background-size: 100% 100%;
  padding: 15px;
  margin-top: 5px;
}

.banner .banner-one {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}

.banner .banner-too {
  font-size: 13px;
  font-weight: 400;
  color: #ccc;
  margin-top: 10px;
}

.banner .banner-three {
  color: #ffffff;
}

.banner div {
  color: #ffffff;
  font-size: 24px;
}

.banner div span {
  font-size: 13px;
  font-weight: 400;
  color: #ccc;
  margin-right: 6px;
}

.banner div .van-rate {
  margin-left: 5px;
}

.banner .van-button--normal {
  width: 100%;
  height: 39px;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 5px;
}

.banner .van-button__text {
  color: #3369fd;
  font-size: 15px;
  font-weight: 500;
}

.banner img {
  width: 12px;
  height: 12px;
}

.info {
  width: 100%;
  /* height: 270px; */
  background-color: #fff;
  margin-top: 10px;
  border-radius: 8px;
  padding: 15px;
}

.min .info h5,
.title {
  font-size: 16px;
  font-weight: 500;
}

.info .info-outside {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.info .info-item {
  width: 315px;
}

.info .info-item img {
  width: 13px;
  height: 13px;
  position: relative;
  top: 2px;
}

.info .info-item span {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-left: 10px;
}

.info .info-item p {
  margin: 6px 0 0 23px;
  font-size: 13px;
  font-weight: 400;
  color: #383a3d;
}

.min .h5 {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: #383a3d;
}

.main .main-item {
  /* width: 332px;
height: 84px; */
  background-color: #fff;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.main-item img {
  width: 50px;
  height: 50px;
  position: relative;
  top: 6px;
  left: -24px;
}

.main-item .item-top {
  font-size: 16px;
  font-weight: 500;
  color: #383a3d;
  position: relative;
  top: -18px;
  left: -20px;
}

.main-item .phone {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-left: 30px;
}

.main-item .phone-number {
  font-size: 13px;
  font-weight: 500;
  color: #383a3d;
}

.main-item .vertical {
  font-size: 14px;
  color: #ccc;
  margin: 0 5px;
}

.main-item-outside {
  display: flex;
  justify-content: space-between;
}

.whole {
  position: relative;
  top: -8px;
  left: -10px;
}

.whole .van-icon {
  position: relative;
  top: 4px;
}

.info-closed {
  color: #3369fd;
  font-size: 12px;
  /* background: chartreuse; */
  position: relative;
  /* left: 45%; */
  padding-bottom: 5px;
  text-align: center;
}

.info-closed img {
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 22px;
  top: 4px;
}

.certification-content {
  color: rgb(77, 73, 73);
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 10px;
}

.button {
  padding: 0 10px;
  box-sizing: border-box;
}

.button button {
  /* width: 100%;
height: 46px;
line-height: 46px; */
  width: 100%;
  height: 50px;
  line-height: 50px;
  /* border-radius: 8px; */
  font-size: 15px;
  padding: 10px;
  /* margin: 10px; */
}

.button .van-icon {
  font-size: 15px;
  position: relative;
  top: 3px;
}
</style>
