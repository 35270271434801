<template>
  <div class="app">
    <!-- <van-nav-bar title="电梯查询" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="ellipsis" size="20" color="#000" />
      </template>
    </van-nav-bar> -->
    <div class="search">
      <van-search
        v-model="value"
        show-action
        shape="round"
        left-icon="scan"
        placeholder="应急识别码/小区名称/电梯名称"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>

    <!-- :head-height="50" -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" > 
	    <div>
			<van-list v-model="loading" :finished="finished" finished-text="到底啦~" error-text="请求失败，点击重新加载"
				@load="onLoad">
			  <van-cell v-for="item in elevatorData" :key="item.id"> 
				<div class="info" @click="onSubmit(item)">
					<h5>{{item.communityName +'  ' + item.systemCode }}</h5>
					<div class="info-outside">
						<div class="info-item">
							<img src="../../assets/info1.png" alt="">
							<span>应急识别码</span>
							<p>{{item.emergencyCode}}</p>
						</div>
						<div class="info-item">
							<img src="../../assets/info2.png" alt="">
							<span>设备代码</span>
							<p>{{item.code}}</p>
						</div>
					</div>
					<div class="info-outside">
						<div class="info-item">
							<img src="../../assets/serial.png" alt="">
							<span>出厂编号</span>
							<p>{{item.produceCode}}</p>
						</div>
						<div class="info-item">
							<img src="../../assets/info6.png" alt="">
							<span>下次年检</span>
							<p>{{item.annualCheckTime | formatDate()}}</p>
						</div>
					</div>
				</div>
			</van-cell>
			<van-swipe-cell></van-swipe-cell>
			</van-list>
	    </div>		
		</van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {getElevatorList} from '../../api/public'

export default {
  data() {
	return {
		loading: false,
		refreshing: false,
		finished: false,
		value: '',
		form: {
			limit: 10,
			page: 1,
		},
		elevatorData:[]
		}
		},
    created(){
		this.onRefresh();
    },
  methods: {
    onSearch() {
		try {
			this.onRefresh();
		} catch (error) {
			console.info(error);
		 }
		},
    onCancel () {
      Toast('取消')
    },
    onClickLeft () {
      this.$router.push('home')
      ///console.log(11)

    },
    onSubmit (item) {
      this.$router.push({ path: 'detailsDt', query:{id: item.id}})
    },
    async onRefresh() {
		this.form.page = 1;
		this.elevatorData = [];
		this.finished = false;
		//this.loading = true;
		this.onLoad();
	},
	async onLoad() {

	   this.loading = true;
	   this.form.name = this.value || null;
	   console.info(this.form)

		getElevatorList(this.form).then(response=>{
			if(this.refreshing){
				setTimeout(() => { 
				   this.refreshing = false;
			    }, 1000);
				//this.loading = false;
			}

			this.elevatorData = this.elevatorData.concat(response.data);
			if (response.data.length < this.form.limit) {
				this.finished = true;
			} else {
				this.form.page++;
				this.finished = false;
			}
			setTimeout(() => { 
				this.loading = false;
			}, 2000);
			 
			}).catch(error=>{
				this.finished = true;
				this.loading = false;
			})
		},
    }
}
</script>

<style scoped>
.search .van-search__action {
  color: #3369FD;
}
.search /deep/ .van-icon-scan {
  margin-right: 5px;
}
.min {
  /* padding-top: 15px; */
}
.info {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.min .info h5 {
  font-size: 18px;
  font-weight: 500;
  color: #383A3D;
}
.info .info-outside {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}
.info .info-item {
  width: 315px;
}
.info .info-item img {
  width: 13px;
  height: 13px;
  position: relative;
  top: 2px;
}
.info .info-item span {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-left: 10px;
}
.info .info-item p {
  margin: 6px 0 0 23px;
  font-size: 13px;
  font-weight: 400;
  color: #383A3D;
}
</style>
