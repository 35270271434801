import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.less'
import '@/styles/index.css'
import 'amfe-flexible'
import wx from 'weixin-jsapi'
import moment from 'moment'
import VueWechatTitle from 'vue-wechat-title'

Vue.prototype.wx = wx

Vue.use(Vant)
Vue.use(VueWechatTitle)

Vue.config.productionTip = false

Vue.filter('formatDate', function (val) {
  if (val) {
    return moment(val).format('YYYY-MM-DD')
  }
})
Vue.filter('formatDateTime', function (val) {
  if (val) {
    return moment(val).format('YYYY-MM-DD HH:mm:ss')
  }
})

Vue.filter('getDicValue', function (value, type) {
  const dicAll = JSON.parse(sessionStorage.getItem('dicList'))
  for (let i = 0; i < dicAll.length; i++) {
    const dic = dicAll[i]
    if (dic.code == type) {
      for (let j = 0; j < dic.childs.length; j++) {
        if (dic.childs[j].key == value) {
          return dic.childs[j].value
        }
      }
      return ''
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
