<template>
  <div class="app">
    <!-- <van-nav-bar title="企业详情" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="ellipsis" size="20" color="#000" />
      </template>
    </van-nav-bar> -->

    <div class="min">
        <div class="banner">
          <p class="banner-one">{{detail.name}}</p>
          <!-- <p class="banner-three">—</p>
          <p class="banner-too">综合评分</p>
          <div>
            5.00
            <van-rate
              v-model="value"
              readonly
              size="20"
              color="#fff"
              void-color="#ccc" />
          </div> -->
        </div>
        <div class="info">
          <h5>基础信息</h5>
          <div class="info-outside">
            <div class="info-item">
              <img
                src="../../assets/info1.png"
                alt="">
              <span>许可证类型</span>
              <div v-show="isShow">
                <div v-for="item in certificationList" :key="item.id" class="info-level">
                  <span>{{(objDict[item.elevatorType]?objDict[item.elevatorType]:'')
                    +'|'+(objDict[item.certificationType]?objDict[item.certificationType]:'')+'|'+
                    item.certificationLevel}}</span>
                </div>
              </div>
              <div @click="closed">
                <span class="info-closed">{{ text }}</span>
                <img
                  :src="imageScreen"
                  alt="">
              </div>
            </div>
            <div class="info-item">
              <img
                src="../../assets/total.png"
                alt="">
              <span>维保电梯数</span>
              <div class="nav">
                <span>{{detail.elevatorCount}}</span>部
              </div>
            </div>
          </div>
          <div class="info-outside">
            <div class="info-item">
              <img
                src="../../assets/info3.png"
                alt="">
              <span>从业人员</span>
              <div class="nav">
                <span>{{detail.userCount}}</span>人
              </div>
            </div>
            <div class="info-item">
              <img
                src="../../assets/info4.png"
                alt="">
              <span>备案日期</span>
              <p>{{detail.recordTime | formatDate()}}</p>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { getCompanyDetail, getCompanyCertificationList, getDictList } from '../../api/public'
export default {
  data () {
    return {
      value: 5,
      isShow: false,
      text: '展开',
      imageScreen: require('@/assets/arrow-x.png'),
      detail: {},
      certificationList: [],
      objDict: {}
    }
  },
  created () {
    this.getCompanyDetail()
    this.getCompanyCertificationList()
    this.getDictList()
  },
  methods: {
    closed () {
      console.log(11)
      this.isShow = !this.isShow
      if (this.isShow === true) {
        this.text = '收起'
        this.imageScreen = require('@/assets/arrow-s.png')
      } else {
        this.text = '展开'
        this.imageScreen = require('@/assets/arrow-x.png')
      }
    },
    onClickLeft () {
      this.$router.push('theQueryWbdw')
    },
    async getCompanyDetail () {
      try {
        const response = await getCompanyDetail({ id: this.$route.query.id })
        console.info('response:')
        if (response.resultCode == 200) {
          this.detail = response.data
        }
      } catch (error) {
        console.info(error)
      }
    },
    async getCompanyCertificationList () {
      try {
        const response = await getCompanyCertificationList({ id: this.$route.query.id })
        console.info(response.data)
        if (response.resultCode == 200) {
          this.certificationList = response.data
        }
      } catch (error) {
      }
    },
    async getDictList () {
      try {
        const response = await getDictList({})
        console.info(response.data)
        if (response.resultCode == 200) {
          if (response.data) {
            for (let i = 0; response.data[i]; i++) {
              const item = response.data[i]
              this.objDict[item.code] = item.name
            }
            console.info(this.objDict)
          }
        }
      } catch (error) {
      }
    }

  }
}
</script>

<style scoped>
/* .min {
  background-color: #F9F9F9;
  padding: 0 15px;
} */
.banner {
  width: 100%;
  background: url(../../assets/bj.png) no-repeat;
  background-size:100% 100%;
  padding: 15px;
  margin-top: 10px;
}
.banner .banner-one {
  font-size: 22px;
  font-weight: 500;
  color: #FFFFFF;
}
.banner .banner-too {
  font-size: 13px;
  font-weight: 400;
  color: rgb(219, 219, 219);
  margin: 4px 0 8px 0;
}
.banner .banner-three {
  color: #FFFFFF;
}
.banner div {
  color: #FFFFFF;
  font-size: 24px;
}
.banner div .van-rate {
  margin-left: 5px;
}
.banner img {
  width: 12px;
  height: 12px;
}
/*   height: 160px; */
.info {
  width: 100%;

  background-color: #fff;
  margin-top: 10px;
  border-radius: 8px;
  padding: 15px;
}
.min .info h5 {
  font-size: 16px;
  font-weight: 500;
}
.info .info-outside {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.info .info-item {
  width: 315px;
}
.info .info-item img {
  width: 13px;
  height: 13px;
  position: relative;
  top: 2px;
}
.info .info-item span {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-left: 10px;
}
.info .info-item p {
  margin: 6px 0 0 23px;
  font-size: 13px;
  font-weight: 400;
  color: #383A3D;
}
.info .info-item .nav {
  margin: 6px 0 0 14px;
  font-size: 13px;
  font-weight: 400;
  color: #383A3D;
}
.info-item .nav span {
  color: #383A3D;
}
.info-outside .info-level {
  font-size: 12px;
  font-weight: 400;
  color: #383A3D;
  margin-top: 6px;
}
.info-outside .info-level span {
  margin: 0;
  color: #383A3D;
  font-size: 10px;
}
.info-outside .info-item .info-closed {
  color: #3369FD;
  margin-left: 22px;
}
.min .h5 {
  margin: 15px 0;
  font-size: 16px;
  font-weight: 500;
  color: #383A3D;
}
.whole {
  position: relative;
  top: -8px;
  left: -10px;
}
.whole .van-icon {
  position: relative;
  top: 4px;
}
</style>
