<template>
  <div class="archive-detail-finish status-bar">
    <div class="info-list">
      <div class="info-item">
        <span>工单号</span>
        <span class="info-item-text">{{ detailData.orderNo }}</span>
      </div>
      <div class="info-item">
        <span>应急识别码</span>
        <span class="info-item-text">{{ detailData.emergencyCode }}</span>
      </div>
      <div class="info-item">
        <span>维保类别</span>
        <span class="info-item-text">{{
          getMaintenanceType(detailData.maintenanceItemName)
        }}</span>
      </div>
      <div class="info-item">
        <span>签到时间</span>
        <span class="info-item-text">{{
          detailData.signinTime | formatDate()
        }}</span>
      </div>
      <div class="info-item">
        <span>签退时间</span>
        <span class="info-item-text">{{
          detailData.signoutTime | formatDate()
        }}</span>
      </div>
      <div class="info-item">
        <span>内部编号</span>
        <span class="info-item-text">{{ detailData.systemCode }}</span>
      </div>
      <div class="info-item">
        <span>设备代码</span>
        <span class="info-item-text">{{ detailData.code }}</span>
      </div>
      <div class="info-item">
        <span>小区名称</span>
        <span class="info-item-text">{{ detailData.communityName }}</span>
      </div>
      <div class="info-item">
        <span>小区地址</span>
        <span class="info-item-text">{{ detailData.communityAddress }}</span>
      </div>
    </div>
    <div
      class="info-list"
      v-for="(el, index) in detailData.orderContentList"
      :key="el.id"
    >
      <div class="info-item">
        <div class="info-item-error">
          <span class="info-item-error-tt">{{ el.maintenanceContent }}</span>
          <span class="info-item-text">{{ el.maintenanceCriterion }}</span>
        </div>
        <div class="info-item-checked">
          <span>{{ getMaintenanceStatus(el.maintenanceStatus) }}</span>
        </div>
      </div>
      <div v-show="el.unfold == true">
        <div class="info-item">
          <span>问题描述</span>
        </div>
        <van-field
          v-model="el.remarks"
          rows="2"
          autosize
          type="textarea"
          maxlength="30"
          placeholder="请输入问题描述"
          readonly
          show-word-limit
        />

        <div class="imgbox">
          <div>
            <div>维保前照片</div>
            <van-image
              v-if="el.beforeUrl"
              width="100"
              height="100"
              :src="el.beforeUrl"
            />
          </div>
          <div>
            <div>维保后照片</div>
            <van-image
              v-if="el.afterUrl"
              width="100"
              height="100"
              :src="el.afterUrl"
            />
          </div>
        </div>
      </div>

      <div
        @click="onunfold(el, index)"
        :class="['info-unfold', el.unfold ? 'up' : 'dowm']"
      >
        <span> {{ el.unfold ? "收起" : "展开" }}</span>
        <img class="info-img" src="../../assets/icon-arrow-black.png" />
      </div>
    </div>

    <div class="info-list">
      <div class="info-item">
        <span>备注</span>
        <span class="info-item-text">{{ detailData.remarks }}</span>
      </div>
    </div>
    <div class="info-list">
      <div class="info-item">
        <span>签到图片</span>
        <van-image
          width="100"
          height="100"
          v-for="item in detailData.maintenanceOrderUserlist"
          :src="item.signinUrl"
          :key="item.id"
        />
      </div>
      <div class="info-item">
        <span>签退图片</span>
        <van-image
          width="100"
          height="100"
          v-for="item in detailData.maintenanceOrderUserlist"
          :src="item.signoutUrl"
          :key="item.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getTaskDetail, getImage } from "../../api/public";

export default {
  data() {
    return {
      detailData: {},
      message: "",
      checked: "",
      signinList: [],
      signoutList: [],
      imgUrl: "",
    };
  },
  created() {
    // if (!sessionStorage.getItem('dicList')) this.getAllDic()
    this.intPage();
  },
  methods: {
    // getAllDic () {
    //   getAllDic().then(res => {
    //     sessionStorage.setItem('dicList', JSON.stringify(res.data))
    //   })
    // },

    // 维保部件检查结果
    getMaintenanceStatus(value) {
      if (value === 1) {
        return "正常";
      } else if (value === 2) {
        return "不正常";
      }
      return "不适用";
    },
    // 维保类型字典值
    getMaintenanceType(value) {
      if (value === "HALF_MONTH") {
        return "半月保";
      } else if (value === "QUARTER") {
        return "季度保";
      } else if (value === "HALF_YEAR") {
        return "半年保";
      } else if (value === "YEAR") {
        return "年度保";
      }
    },
    async intPage() {
      const id = this.$route.query.id;
      try {
        const res = await getTaskDetail({ id: id });

        if (res.data != null) {
          res.data.orderContentList.map((el, index) => {
            el.unfold = false;

            el.beforeUrl = "";
            el.afterUrl = "";
          });

          if (res.data.maintenanceOrderUserlist.length > 0) {
            res.data.maintenanceOrderUserlist.map((item) => {
              item.signinUrl = "";
              item.signoutUrl = "";
            });
            this.signoutImg(res.data.maintenanceOrderUserlist);
          }
          this.detailData = res.data;
        }
      } catch (err) {}
    },

    getImg(id, index, str) {
      getImage({
        id: id,
      }).then((res) => {
        this.detailData.orderContentList[index][str] =
          "data:image/png;base64," + res.data;
      });
    },

    signoutImg(array) {
      array.map((item) => {
        if (item.signinImg) {
          getImage({
            id: item.signinImg,
          }).then((res) => {
            item.signinUrl = "data:image/png;base64," + res.data;
          });
        }
        if (item.signoutImg) {
          getImage({
            id: item.signoutImg,
          }).then((res) => {
            item.signoutUrl = "data:image/png;base64," + res.data;
          });
        }
      });
    },

    // 展开维保事项
    onunfold(el, index) {
      el.unfold = !el.unfold;

      if (el.unfold && el.contentBeforeImg && el.beforeUrl === "") {
        this.getImg(el.contentBeforeImg, index, "beforeUrl");
      }

      if (el.unfold && el.contentAfterImg && el.afterUrl === "") {
        this.getImg(el.contentAfterImg, index, "afterUrl");
      }
    },
  },
};
</script>

<style scoped>
.info-item {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-item-text {
  color: #999;
}
.imgbox {
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  display: flex;
}
.imgbox > div {
  flex: 1;
}
.imgbox > div > div {
  margin-bottom: 10px;
}
.info-img {
  width: 12px;
  height: 12px;
}
.info-line {
  padding: 0px 5px;
  width: 100%;
  height: 1px;
}
.info-item-error-tt {
  margin-bottom: 10px;
}
.info-item-error {
  display: flex;
  flex-direction: column;
}
.info-item-checked {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-left: 10px;
}
.info-unfold {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #0c58dc;
}
.info-unfold .info-img {
  margin-left: 3px;
  width: 12px;
  height: 12px;
}
.up .info-img {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg);
  position: relative;
  top: 2px;
}

.van-switch {
  margin-left: 5px;
}
.dowm .info-img {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
  -o-transform: rotate(90deg);
}
.van-button {
  margin: 0 10%;
  width: 30%;
}
.scan-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.info-list {
  display: flex;
  background: #fff;
  margin: 10px;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  font-size: 12px;
}
.archive-detail-finish {
  background-color: #f2f2f2;
}
</style>
