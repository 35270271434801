<template>
	<div class="null-page">
		<van-loading type="spinner" size="36px" color="#1989fa" vertical>加载中...</van-loading>
	</div>
</template>

<script>
import {
  wxJsJdkSignature,
  getWxUserInfo,
  getAllDic
} from '@/api/public'
import {
  setWxUser,
  getWxUser
} from '@/utils'
import wx from 'weixin-jsapi'
import {
  Toast
} from 'vant'
export default {
  components: {},
  data () {
    return {
      text: '找不到电梯'
    }
  },
  created () {
    this.getCode()
  },
  methods: {
    async getCode () {
      try {
        // 非静默授权，第一次有弹框
        this.code = ''
        var local = window.location.href // 获取页面url
        // var local = 'http://zy.nndtyun.com/monitor/api/pub/wx/test'
        // var appid = 'wx19b5db029172b30f';
        var appid = 'wx7ff0e2659ee777f6'
        this.code = this.getUrlCode().code // 截取code

        if (this.code == null || this.code === '') { // 如果没有code，则去请求
          const state = '1'
          // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
          window.location.replace(
							`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=${state}&connect_redirect=1#wechat_redirect`
          )
        } else {
          // console.info('getWx');
          this.getWxUserInfo()
          this.getSign()
          this.getAllDic()
        }

        // this.getHomeData();
      } catch (error) {}
    },
    // 截取url中的code方法
    getUrlCode () {
      const url = location.search
      this.winUrl = url
      const theRequest = new Object()
      if (url.indexOf('?') != -1) {
        const str = url.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
        }
      }
      return theRequest
    },

    // 授权获取微信用户信息
    async getWxUserInfo () {
      try {
        if (getWxUser().openid) {
          this.$router.replace({
            path: 'Home'
          })
          return
        }

        const query = {
          code: this.code
        }
        const response = await getWxUserInfo(query)

        if (response.resultCode == 200) {
          const wxuserInfo = response.data

          if (wxuserInfo.openid) {
            setWxUser(wxuserInfo)
            this.$router.replace({
              path: 'Home'
            })
          }
        }
      } catch (error) {}
    },

    getSign () {
      try {
        const that = this
        const webUrl = window.location.href // .split('?')[0];
        wxJsJdkSignature({
          webUrl: webUrl
        }).then(response => {
          if (response.resultCode == 200) {
            that.jsJDKConfig(response.data)
          } else {
            Toast.fail('获取签名失败')
          }
        })
      } catch (error) {}
    },
    jsJDKConfig (signs) {
      wx.config({
        debug: false,
        appId: signs.appId,
        timestamp: signs.timestamp,
        nonceStr: signs.nonceStr,
        signature: signs.sign,
        jsApiList: ['scanQRCode']
      })
    },
    getAllDic () {
      getAllDic().then(res => {
        sessionStorage.setItem('dicList', JSON.stringify(res.data))
      })
    }

  }
}
</script>

<style scoped>
	.null-page {
		background-color: #F2F2F2;
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
	}

	.img {
		width: 200px;
		height: 150px;
	}

	p {
		margin-top: 10px;
		font-size: 16px;
		color: #999;
	}
</style>
