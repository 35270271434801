import { httpGet, httpPost, httpPut, httpDelete } from '@/axios/http'

const prefix = '/monitor/api/pub'

export const getHomeData = (params) => {
  return httpGet({
    url: `${prefix}/elevator/fristPageInfo`,
    params: params
  })
}

export const getElevatorList = (params) => {
  return httpGet({
    url: `${prefix}/elevator/query`,
    params: params
  })
}

export const getElevatorIot = (params) => {
  return httpGet({
    url: 'https://iot-api.nndtyun.com/iot/equipment/iotelevator',
    params
  })
}

export const getElevatorDetail = (params) => {
  return httpGet({
    url: `${prefix}/elevator/detail`,
    params: params
  })
}

export const getCompanyList = (params) => {
  return httpGet({
    url: `${prefix}/company/query`,
    params: params
  })
}

export const getCompanyDetail = (params) => {
  return httpGet({
    url: `${prefix}/company/detail`,
    params: params
  })
}

export const getCompanyCertificationList = (params) => {
  return httpGet({
    url: `${prefix}/company/certificationList`,
    params: params
  })
}

// 获取微信信息
export const getWxUserInfo = (params) => {
  return httpGet({
    url: `${prefix}/wx/wxUserInfo`,
    params: params
  })
}

// 关注电梯
export const attention = (params) => {
  return httpPost({
    url: `${prefix}/wx/attention`,
    data: params
  })
}

// 取消关注电梯
export const cancelAttention = (params) => {
  return httpPut({
    url: `${prefix}/wx/cancelAttention`,
    params: params
  })
}

// 获取js-jdk签名信息
export const wxJsJdkSignature = (params) => {
  return httpGet({
    url: `${prefix}/wx/wxJsJdkSignature`,
    params: params
  })
}

// 判断是否关注公众号
export const isAttentionPub = (params) => {
  return httpGet({
    url: `${prefix}/wx/isAttentionPub`,
    params: params
  })
}

// 获取资质类型数据
export const getDictList = (params) => {
  return httpGet({
    url: `${prefix}/company/dictList`,
    params: params
  })
}

// 数据字典
export const getAllDic = (params) => {
  return httpGet({
    url: 'monitor/api/pc/sysDict/getAllDict',
    params: params
  })
}

// 维保详情
export const getTaskDetail = (params) => {
  return httpGet({
    url: 'monitor/api/app/maintenanceOrder/getMaintenanceOrderDetails',
    params: params
  })
}

// 获取图片
export const getImage = (params) => {
  return httpGet({
    url: 'monitor/api/show/image/base64',
    params: params
  })
}
