
export function getAppId() {
    const appId = 'wx19b5db029172b30f';
    return appId;
}

//扫码解析
export function scanResultAnalysis(result){
    const prefixe = 'http://dt.cloudbae.cn/i.do?e=';

    const prefix= 'http://dt.cloudbae.cn/i.do?e'
    
    if(result.indexOf(prefixe) != -1){
      const emergencyCode = result.substr(result.lastIndexOf('=') + 1);
      return emergencyCode;
    }
    else if(result.indexOf(prefix) != -1){
      const emergencyCode = result.substr(result.lastIndexOf('e') + 1);
      return emergencyCode;
    }
    else{
      return false;
    }
  }

  const userKey= 'gxdtypt-monitor-wxuser'
  export function setWxUser(user){
    //localStorage.setItem(userKey, JSON.stringify(user));
    window.sessionStorage.setItem(userKey, JSON.stringify(user));
  }

  export function getWxUser(){
    //const user = localStorage.getItem(userKey);
    const user = window.sessionStorage.getItem(userKey);
    if(user){
      return JSON.parse(user);
    }
    return '';
  }
