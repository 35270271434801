<template>
  <div class="scan-detail">
    <div class="info-list">
      <div class="info-item">
        <span>{{ result }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components:{
    },
    data() {
      return {
        searchValue:'',
        result: this.$route.query.result,
      };
    },
    methods: {
    }
  };
</script>

<style scoped >

  .scan-detail{
    background-color: #F2F2F2;
    width: 100%;
  }
  .info-list{
      display: flex;
      background: #fff;
      margin: 10px;
      border-radius: 5px;
      flex-direction: column;
      padding: 10px;
    }
  .info-item{
    padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
      }

  .span{
    margin: 10px;
  }         
</style>
